<template>
  <div>
    <Dialog
      v-model="errorSummary"
      v-model:visible="showErrorDialog"
      :style="{ width: '600px' }"
      header="Errors"
      :modal="true"
      class="p-fluid"
      :closeable="false"
      @hide="
        errorSummary = null;
        selectedEntity = { race: {}, horse: {}, type: '' };
      "
    >
      <div>
        {{ errorSummary }}
      </div>

      <template #footer>
        <Button
          label="Clear Errors"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            clearErrors(selectedEntity);
            selectedEntity = { race: {}, horse: {}, type: '' };
            errorSummary = null;
            showErrorDialog = false;
          "
        />
        <Button
          label="Close"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            errorSummary = null;
            showErrorDialog = false;
            selectedEntity = { race: {}, horse: {}, type: '' };
          "
        />
      </template>
    </Dialog>
    <Dialog
      v-model="selectedRace"
      v-model:visible="showEditRaceDialog"
      :style="{ width: '1400px' }"
      header="Edit Race Details"
      :modal="true"
      class="p-fluid"
      :closeable="false"
      @hide="selectedRace = null"
    >
      <div class="row">
        <div class="v-col-6">
          <div class="v-row c-dialog-row">
            <div class="field v-col">
              <label for="selectedRaceName">Name</label>
              <InputText id="selectedRaceName" v-model="selectedRace['@_name']">
              </InputText>
            </div>
          </div>
          <div class="v-row c-dialog-row">
            <div class="field v-col">
              <label for="selectedRaceRaName">RA Name</label>
              <InputText
                id="selectedRaceRaName"
                v-model="selectedRace['@_ra_name']"
                disabled
              >
              </InputText>
            </div>

            <div class="field v-col-4">
              <label for="selectedRaceRaName">Track</label>

              <AutoComplete
                v-model="selectedRace.track_name"
                :dropdown="true"
                :suggestions="filteredTracks"
                field="label"
                @complete="searchTrack"
              />
            </div>
          </div>
          <div class="v-row c-dialog-row">
            <div class="field v-col">
              <label for="selectedRaceShortName">ShortName</label>
              <InputText
                id="selectedRaceShortName"
                v-model="selectedRace['@_shortname']"
              >
              </InputText>
            </div>

            <div class="field v-col">
              <label for="selectedRaceStage">Stage</label>
              <Dropdown
                id="selectedRaceStage"
                v-model="selectedRace.race_stage"
                :options="race_stages"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>
          </div>
          <div class="v-row c-dialog-row">
            <div class="field v-col">
              <label for="selectedRaceClass">Class</label>
              <AutoComplete
                v-model="selectedRace.class"
                id="selectedRaceClass"
                :suggestions="this.filteredClasses"
                @complete="searchClass"
                :dropdown="true"
                field="label"
                autoHighlight
                forceSelection
              >
              </AutoComplete>
            </div>
            <div class="field v-col">
              <label for="selectedRaceSecondClass">Secondary Class</label>
              <AutoComplete
                v-model="selectedRace.second_class"
                id="selectedRaceSecondClass"
                :suggestions="this.filteredClasses"
                @complete="searchClass"
                :dropdown="true"
                field="label"
                autoHighlight
                forceSelection
              >
              </AutoComplete>
            </div>
            <div class="field v-col">
              <label for="selectedRaceThirdClass">Tertiary Class</label>
              <AutoComplete
                v-model="selectedRace.third_class"
                id="selectedRaceThirdClass"
                :suggestions="this.filteredClasses"
                @complete="searchClass"
                :dropdown="true"
                field="label"
                autoHighlight
                forceSelection
              >
              </AutoComplete>
            </div>
          </div>
          <div class="v-row c-dialog-row">
            <div class="field v-col-2">
              <label for="selectedRaceTime">Race time</label>
              <InputText
                id="selectedRaceTime"
                v-model="selectedRace.start_time"
                :useGrouping="false"
              >
              </InputText>
            </div>
            <div class="field v-col-2">
              <label for="selectedRaceDistance">Distance</label>
              <InputNumber
                id="selectedRaceDistance"
                v-model="selectedRace.distance['@_metres']"
                :useGrouping="false"
              >
              </InputNumber>
            </div>

            <div class="field v-col-3">
              <label for="selectedRaceType">Race Type</label>
              <Dropdown
                id="selectedRaceType"
                v-model="selectedRace.race_type"
                :options="race_types"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>
            <div class="field v-col-2">
              <label for="selectedRaceGroup">Group</label>
              <InputText id="selectedRaceGroup" v-model="selectedRace.group">
              </InputText>
            </div>
          </div>
          <div class="v-row c-dialog-row">
            <div class="field v-col-3">
              <label for="selectedRaceAgeRest">Age Rest</label>
              <Dropdown
                id="selectedRaceAgeRest"
                v-model="selectedRace.restrictions['@_age']"
                :options="agerest_options"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>
            <div class="field v-col-3">
              <label for="selectedRaceSexRest">Sex Rest</label>
              <Dropdown
                id="selectedRaceSexRest"
                v-model="selectedRace.restrictions['@_sex']"
                :options="sexrest_options"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>

            <div class="field v-col">
              <label for="selectedRaceJockeyRest">Jockey Rest</label>
              <Dropdown
                id="selectedRaceJockeyRest"
                v-model="selectedRace.restrictions['@_jockey']"
                :options="jockeyrest_options"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>
          </div>
          <div class="v-row c-dialog-row">
            <div class="field v-col-8">
              <label for="selectedRaceWeightType">Weight Type</label>
              <Dropdown
                id="selectedRaceWeightType"
                v-model="selectedRace.weight_type"
                :options="weight_types"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>
            <div class="field v-col-2">
              <label for="selectedRaceWeightMin">Min Weight:</label>
              <InputText
                id="selectedRaceWeightMin"
                v-model="selectedRace.min_hcp_weight"
              >
              </InputText>
            </div>
          </div>
          <div v-if="user_role < 4" class="v-row c-dialog-row">
            <div class="field v-col">
              <div class="field-checkbox col active-section">
                <Checkbox
                  id="binary"
                  v-model="selectedRace.single_horse"
                  :binary="true"
                />
                <label for="binary"
                  >This Race will distribute with only the first horse.</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="v-col-3">
          <div class="v-row c-dialog-row" style="border-left: 1px solid grey">
            <div
              class="field v-col-1"
              style="margin-top: -60px; position: absolute"
            >
              <b>Prizemoney</b>
            </div>
            <div
              class="field v-col-12"
              v-for="(prize, index) in selectedRace.prizes.prize"
              :key="selectedRace.prizes.prize[index]"
            >
              <div class="row">
                <label style="width: 40%" :for="'prize' + prize['@_type']">{{
                  prize["@_type"]
                }}</label>
                <InputNumber
                  style="border: 1px solid #bbb; width: 50%; float: right"
                  class="p-prizelabel"
                  :id="'prize' + prize['@_type']"
                  v-model="prize['@_value']"
                >
                </InputNumber>
              </div>
            </div>
          </div>
        </div>
        <div class="v-col-3">
          <div class="v-row c-dialog-row" style="border-left: 1px solid grey">
            <div
              class="field v-col-1"
              style="margin-top: -60px; position: absolute"
            >
              <b>Results</b>
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceCondition">Condition</label>
              <Dropdown
                id="resultsRaceCondition"
                v-model="selectedRace.track_condition"
                :options="condition_options"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceDuration">Duration</label>
              <InputText
                id="resultsRaceDuration"
                v-model="selectedRace.duration"
              >
              </InputText>
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceOMargin1">Off Margin 1</label>
              <!-- <InputText
                  id="resultsRaceOMargin1"
                  v-model="selectedRace.official_margin_1"
                  
              >
              </InputText> -->

              <AutoComplete
                v-model="selectedRace.official_margin_1"
                id="resultsRaceOMargin1"
                :suggestions="filteredMargins"
                @complete="searchMargin"
                :dropdown="true"
                autoHighlight
                field="label"
              />
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceOMargin2">Off Margin 2</label>
              <!-- <InputText
                  id="resultsRaceOMargin2"
                  v-model="selectedRace.official_margin_2"
                  
              >
              </InputText> -->
              <AutoComplete
                v-model="selectedRace.official_margin_2"
                id="resultsRaceOMargin2"
                :suggestions="filteredMargins"
                autoHighlight
                :dropdown="true"
                field="label"
                @complete="searchMargin"
              />
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceStarters">Starters</label>
              <InputText
                id="resultsRaceStarters"
                v-model="selectedRace.starters"
              >
              </InputText>
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceTimingMethod">Manual Timing Method</label>
              <InputText
                id="resultsRaceTimingMethod"
                v-model="selectedRace.timing_method"
              >
              </InputText>
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceSectionalTime">Sectional Time</label>
              <InputText
                id="resultsRaceSectionalTime"
                v-model="selectedRace.sec_timing"
              >
              </InputText>
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceSectionalDistance"
                >Sectional Distance</label
              >
              <InputText
                id="resultsRaceSectionalDistance"
                v-model="selectedRace.sec_distance"
              >
              </InputText>
            </div>
            <div class="field v-col-12">
              <label for="resultsRaceSectionalIndicator"
                >Sectional Indicator</label
              >
              <Dropdown
                id="resultsRaceSectionalIndicator"
                v-model="selectedRace.sec_indicator"
                :options="[
                  { value: 'Last', label: 'Last' },
                  { value: 'First', label: 'First' },
                ]"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="showEditRaceDialog = false"
        />
        <Button
          label="Update"
          icon="pi pi-check"
          class="p-button-text"
          @click="updateRace()"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="showDeleteConfirmDialog"
      header="Confirmation"
      modal
      :closable="false"
      :style="{ width: '450px' }"
      @hide="showDeleteConfirmDialog = false"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
        <span>Are you sure you want to delete this race?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="showDeleteConfirmDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteRaceConfirmed()"
        />
      </template>
    </Dialog>

    <Accordion v-if="races" lazy>
      <AccordionTab v-for="(race, index) in races" :key="race[index]">
        <template #header>
          <div v-if="race.errors">
            <button
              v-if="race.errors.length"
              class="pi pi-info-circle"
              style="
                color: red;
                position: absolute;
                margin: 0 0px 0px -25px;
                top: 0;
                left: 25px;
              "
              @click.stop="
                selectedEntity.race = race;
                selectedEntity.type = 'race';
                errorSummary = race.errors;
                showErrorDialog = true;
              "
            ></button>
          </div>
          <v-icon icon="mdi-horse-variant-fast"></v-icon>
          <div class="v-col-4 d-flex justify-start" style="padding: initial">
            &nbsp; {{ race["@_number"] }} - {{ race["@_name"] }}
          </div>
          <div
            class="v-col d-flex justify-start"
            style="padding: initial; font-weight: normal"
          >
            <div v-if="race.race_stage">
              {{ race.race_stage }}
            </div>
            <div v-else><b>Stage:&nbsp; </b> Unknown</div>
          </div>
          <div
            class="v-col d-flex justify-start"
            style="padding: initial; font-weight: normal"
          >
            <div v-if="race.distance">
              {{ race.distance["@_metres"] }}m{{
                race.track_name != race.meeting_track
                  ? ` (${race.track_name})`
                  : ""
              }}
            </div>
            <div v-else><b>Distance:&nbsp; </b> Unknown</div>
          </div>
          <div
            class="v-col d-flex justify-start"
            style="padding: initial; font-weight: normal"
          >
            <div v-if="race.start_time">
              {{ getTime(race.start_time) }}
            </div>
          </div>
          <div
            v-if="race.group == ''"
            class="v-col d-flex justify-start"
            style="padding: initial; font-weight: normal"
          >
            <div v-if="race.class">
              {{ race.class.value ?? race.class }}
            </div>
            <div v-else><b>Class:&nbsp; </b> Unknown</div>

            <div v-if="race.second_class">
              {{ "&nbsp;" + (race.second_class.value ?? race.second_class) }}
            </div>

            <div v-if="race.third_class">
              {{ "&nbsp;" + (race.third_class.value ?? race.third_class) }}
            </div>
          </div>
          <div
            v-if="race.group != ''"
            class="v-col d-flex justify-start"
            style="padding: initial; font-weight: normal"
          >
            <div>
              {{
                race.group == "LR" ? "Listed Race" : "Group&nbsp;" + race.group
              }}
            </div>
          </div>
          <div
            class="v-col d-flex justify-start"
            style="padding: initial; font-weight: normal"
          >
            <div
              v-if="race.prizes && race.prizes.prize && race.prizes.prize[0]"
            >
              {{
                formatCurrency(
                  race.prizes.prize.filter((p) => {
                    return p["@_type"] == "total_value";
                  })[0]["@_value"]
                )
              }}
            </div>
            <div v-else><b>Prizemoney:&nbsp; </b> Unknown</div>
          </div>
          <div class="v-col-1 d-flex">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-primary mr-3"
              @click.stop="
                selectedRace = race;
                showEditRaceDialog = true;
              "
            />
            <Button
              v-if="
                race.race_stage !== currentMeetingStage &&
                !(
                  (currentMeetingStage === 'Acceptances' ||
                    currentMeetingStage === 'FinalFields') &&
                  (race.race_stage === 'Acceptances' ||
                    race.race_stage === 'FinalFields')
                ) &&
                currentMeetingStage !== 'Results' &&
                currentMeetingStage !== 'Interim Results' &&
                currentMeetingStage !== 'InterimResults'
              "
              icon="pi pi-times"
              class="p-button-rounded p-button-primary mr-3"
              @click.stop="confirmDeleteRace(race)"
              style="background-color: red; border-color: white"
            />

            <Button
              v-if="
                race.race_stage &&
                race.race_stage.toLowerCase().includes('results') &&
                !race.results_approved
              "
              :class="
                'p-button p-component p-button-icon-only p-button-rounded p-button-primary pi-results-unchecked j-results-' +
                race['@_id']
              "
              icon="pi pi-circle-on"
            />
            <Button
              v-else-if="
                race.race_stage &&
                race.race_stage.toLowerCase().includes('results') &&
                race.results_approved
              "
              class="p-button p-component p-button-icon-only p-button-rounded p-button-primary pi-results-checked"
              icon="pi pi-check-circle"
            />
            <div v-else></div>
          </div>
        </template>
        <div class="formgrid grid">
          <v-col v-if="race.age_rest">
            <div><b>Age:&nbsp; </b> {{ race.age_rest }}</div>
          </v-col>
          <v-col v-if="race.sex_rest">
            <div><b>Sex:&nbsp; </b> {{ race.sex_rest }}</div>
          </v-col>
          <v-col v-if="race.jockey_rest">
            <div><b>Claim:&nbsp; </b> {{ race.apprentice_claim }}</div>
          </v-col>
          <v-col v-if="race.weight_type">
            <div><b>Weight Type:&nbsp; </b> {{ race.weight_type }}</div>
          </v-col>
          <v-col v-if="race.min_hcp_weight">
            <div><b>Min weight:&nbsp; </b> {{ race.min_hcp_weight }}</div>
          </v-col>
          <v-col v-if="race.race_type">
            <div><b>Race type:&nbsp; </b> {{ race.race_type }}</div>
          </v-col>
          <v-col v-if="race.track_type">
            <div><b>Track type:&nbsp; </b> {{ race.track_type }}</div>
          </v-col>
          <div
            v-if="race.group != ''"
            class="v-col d-flex justify-start"
            style="padding: initial; font-weight: normal"
          >
            <div v-if="race.class">
              {{ race.class.value ?? race.class }}
            </div>
            <div v-else><b>Class:&nbsp; </b> Unknown</div>

            <div v-if="race.second_class">
              {{ "&nbsp;" + (race.second_class.value ?? race.second_class) }}
            </div>
            <div v-if="race.third_class">
              {{ "&nbsp;" + (race.third_class.value ?? race.third_class) }}
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-row-reverse flex-wrap">
          <Button disabled>Edit Race Details...</Button>
        </div> -->

        <TabView>
          <TabPanel
            v-if="race.horses && race.race_stage == 'Results'"
            header="Results"
          >
            <div class="formgrid grid">
              <v-col v-if="race.track_condition">
                <div><b>Track Cond:&nbsp; </b> {{ race.track_condition }}</div>
              </v-col>
              <v-col v-if="race.duration">
                <div><b>Duration:&nbsp; </b> {{ race.duration }}</div>
              </v-col>
              <v-col v-if="race.timing_method">
                <div><b>Manual timing:&nbsp; </b> {{ race.timing_method }}</div>
              </v-col>
            </div>
            <div class="formgrid grid" v-if="race.sec_timing">
              <v-col>
                <div><b>Sectional Time:&nbsp; </b> {{ race.sec_timing }}</div>
              </v-col>
              <v-col v-if="race.sec_distance">
                <div>
                  <b>Sectional Distance:&nbsp; </b> {{ race.sec_distance }}
                </div>
              </v-col>
              <v-col v-if="race.sec_indicator">
                <div>
                  <b>Sectional Indicator:&nbsp; </b> {{ race.sec_indicator }}
                </div>
              </v-col>
            </div>
            <div class="formgrid grid" v-if="race.ra_comments">
              <v-col v-if="race.sec_indicator">
                <div><b>RA Comment:&nbsp; </b> {{ race.ra_comments }}</div>
              </v-col>
            </div>
            <div class="formgrid grid">
              <v-col>
                <div>
                  <b>Results approved:&nbsp; </b>
                  {{ race.results_approved ?? false }}
                </div>
              </v-col>
              <div class="v-col-3 d-flex">to approve results --></div>
              <div class="v-col-1 d-flex">
                <Button
                  icon="pi pi-check-circle"
                  class="p-button-rounded p-button-primary mr-3"
                  @click.stop="submitResultsFormHandler(race)"
                />
              </div>
              <div class="v-col-2 d-flex justify-start">
                <Button
                  label="Distribute"
                  icon="pi pi-check"
                  iconPos="right"
                  class="p-button-distribute"
                  @click.stop="
                    distributeResultRace(meetingId, race['@_number'])
                  "
                >
                </Button>
              </div>
            </div>
            <DataTable
              v-if="race.horses"
              v-model:editingRows="editingRowsResults"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="row"
              responsiveLayout="scroll"
              sortMode="multiple"
              :multiSortMeta="resultsSort"
              @row-edit-save="onRowEditSaveResults($event, race)"
            >
              <Column field="errors" style="font-size: 0">
                <template #body="slotProps">
                  <button
                    class="pi pi-info-circle"
                    :style="
                      slotProps.data.errors.length > 0
                        ? 'color: red'
                        : 'display: none'
                    "
                    @click.stop="
                      selectedEntity.horse = slotProps.data;
                      selectedEntity.race = race;
                      selectedEntity.type = 'horse';
                      errorSummary = slotProps.data.errors;
                      showErrorDialog = true;
                    "
                  ></button>
                  {{ slotProps.data.errors }}
                </template>
              </Column>
              <Column field="tab_number" header="No" :sortable="true"> </Column>
              <Column field="@_name" header="Horse Name"> </Column>
              <Column field="finish_position" header="FP" :sortable="true">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="jockeyName" header="Jockey">
                <template #editor="{ data, field }">
                  <AutoComplete
                    v-model="data[field]"
                    :dropdown="true"
                    :suggestions="filteredJockeys"
                    field="label"
                    @complete="searchJockey"
                  />
                </template>
              </Column>
              <Column header="WAdj">
                {{
                  slotProps.data.weight_carried - slotProps.data.allocatedWeight
                }}
              </Column>
              <Column field="allocatedWeight" header="AWt">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="weight_carried" header="CWt">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_settling_down" header="SD">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_m1200" header="1200">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_m800" header="800">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_m400" header="400">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="margin" header="Margin">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="price_opening" header="Open">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column field="price_mid" header="Fl 1">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <!-- <Column field="price_mid_2" header="Fl 2">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput"/>
                </template>
              </Column> -->
              <Column field="price_start" header="Start">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column field="prizemoney_race" header="PMy" :sortable="true">
                <template #editor="{ data, field }">
                  <InputNumber
                    v-model="data[field]"
                    :maxFractionDigits="0"
                    class="smallInputCurrency"
                    currency="AUD"
                    locale="en-AU"
                    mode="currency"
                  />
                </template>
                <template #body="{ data, field }">
                  {{ formatCurrency(data[field]) }}
                </template>
              </Column>
              <Column field="prizemoney_bonus" header="Bon" :sortable="true">
                <template #editor="{ data, field }">
                  <InputNumber
                    v-model="data[field]"
                    :maxFractionDigits="0"
                    class="smallInputCurrency"
                    currency="AUD"
                    locale="en-AU"
                    mode="currency"
                  />
                </template>
                <template #body="{ data, field }">
                  {{ formatCurrency(data[field]) }}
                </template>
              </Column>
              <Column :rowEditor="true" bodyStyle="text-align:center"></Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    class="p-button-rounded p-button-danger p-button-text"
                    icon="pi pi-times"
                    @click="toggleScratching(slotProps.data, race)"
                  />
                </template>
              </Column>
              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse p-scratched-horse-sml"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel v-if="race.horses" header="Fields">
            <DataTable
              v-model:editingRows="editingRowsFields"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="row"
              responsiveLayout="scroll"
              @row-edit-save="onRowEditSaveFields($event, race)"
              sortMode="multiple"
              :multiSortMeta="fieldSort"
            >
              <!-- <div v-if="race.horses.horse.errors.length > 0">
                <i
                    class="pi pi-times-circle"
                    style="color: red"
                    :alt="race.horses.horse.errors.join(',')"
                ></i>
              </div> -->
              <Column field="errors" style="font-size: 0">
                <template #body="slotProps">
                  <button
                    class="pi pi-info-circle"
                    :style="
                      slotProps.data.errors.length > 0
                        ? 'color: red'
                        : 'display: none'
                    "
                    @click.stop="
                      selectedEntity.horse = slotProps.data;
                      selectedEntity.race = race;
                      selectedEntity.type = 'horse';
                      errorSummary = slotProps.data.errors;
                      showErrorDialog = true;
                    "
                  ></button>
                  {{ slotProps.data.errors }}
                </template>
              </Column>

              <Column field="tab_number" header="No" :sortable="true">
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="@_name" header="Horse"> </Column>

              <Column field="emergency_indicator" header="E">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column field="trainerName" header="Trainer">
                <template #editor="{ data, field }">
                  <AutoComplete
                    v-model="data[field]"
                    :dropdown="true"
                    :suggestions="filteredTrainers"
                    field="label"
                    @complete="searchTrainer"
                  />
                </template>
              </Column>
              <Column field="allocatedWeight" header="Wt" :sortable="true">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>

              <Column field="jockeyName" header="Jockey">
                <template #editor="{ data, field }">
                  <AutoComplete
                    v-model="data[field]"
                    :dropdown="true"
                    :suggestions="filteredJockeys"
                    field="label"
                    @complete="searchJockey"
                  />
                </template>
              </Column>
              <Column field="jockeyWeight" header="Cl">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column field="barrier" header="Bar">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column field="colours" header="Silks">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="wideInput" />
                </template>
              </Column>

              <Column
                :rowEditor="true"
                bodyStyle="text-align:center"
                style="width: 10%; min-width: 8rem"
              ></Column>
              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                  <button
                    class="pi pi-times-circle"
                    :style="
                      slotProps.data.scratched ? 'color: red;' : 'color: black;'
                    "
                    @click.stop="
                      toggleScratching(slotProps.data, race);
                      slotProps.data.scratched
                        ? (slotProps.data.scratched = false)
                        : (slotProps.data.scratched = true);
                    "
                  ></button>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel
            v-if="race.horses && race.race_stage != 'Results'"
            header="Results"
          >
            <div class="formgrid grid">
              <v-col v-if="race.track_condition">
                <div><b>Track Cond:&nbsp; </b> {{ race.track_condition }}</div>
              </v-col>
              <v-col v-if="race.duration">
                <div><b>Duration:&nbsp; </b> {{ race.duration }}</div>
              </v-col>
              <v-col v-if="race.timing_method">
                <div><b>Manual timing:&nbsp; </b> {{ race.timing_method }}</div>
              </v-col>
            </div>
            <div class="formgrid grid" v-if="race.sec_timing">
              <v-col>
                <div><b>Sectional Time:&nbsp; </b> {{ race.sec_timing }}</div>
              </v-col>
              <v-col v-if="race.sec_distance">
                <div>
                  <b>Sectional Distance:&nbsp; </b> {{ race.sec_distance }}
                </div>
              </v-col>
              <v-col v-if="race.sec_indicator">
                <div>
                  <b>Sectional Indicator:&nbsp; </b> {{ race.sec_indicator }}
                </div>
              </v-col>
            </div>
            <div class="formgrid grid" v-if="race.ra_comments">
              <v-col v-if="race.sec_indicator">
                <div><b>RA Comment:&nbsp; </b> {{ race.ra_comments }}</div>
              </v-col>
            </div>
            <div class="formgrid grid">
              <v-col>
                <div>
                  <b>Results approved:&nbsp; </b>
                  {{ race.results_approved ?? false }}
                </div>
              </v-col>
              <div class="v-col-3 d-flex">to approve results --></div>
              <div class="v-col-1 d-flex">
                <Button
                  icon="pi pi-check-circle"
                  class="p-button-rounded p-button-primary mr-3"
                  @click.stop="submitResultsFormHandler(race)"
                />
              </div>
              <div class="v-col-2 d-flex justify-start">
                <Button
                  label="Distribute"
                  icon="pi pi-check"
                  iconPos="right"
                  class="p-button-distribute"
                  @click.stop="
                    distributeResultRace(meetingId, race['@_number'])
                  "
                >
                </Button>
              </div>
            </div>
            <DataTable
              v-if="race.horses"
              v-model:editingRows="editingRowsResults"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="row"
              responsiveLayout="scroll"
              sortMode="multiple"
              :multiSortMeta="resultsSort"
              @row-edit-save="onRowEditSaveResults($event, race)"
            >
              <Column field="errors" style="font-size: 0">
                <template #body="slotProps">
                  <button
                    class="pi pi-info-circle"
                    :style="
                      slotProps.data.errors.length > 0
                        ? 'color: red'
                        : 'display: none'
                    "
                    @click.stop="
                      selectedEntity.horse = slotProps.data;
                      selectedEntity.race = race;
                      selectedEntity.type = 'horse';
                      errorSummary = slotProps.data.errors;
                      showErrorDialog = true;
                    "
                  ></button>
                  {{ slotProps.data.errors }}
                </template>
              </Column>
              <Column field="tab_number" header="No" :sortable="true"> </Column>
              <Column field="@_name" header="Horse Name"> </Column>
              <Column field="finish_position" header="FP" :sortable="true">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="jockeyName" header="Jockey">
                <template #editor="{ data, field }">
                  <AutoComplete
                    v-model="data[field]"
                    :dropdown="true"
                    :suggestions="filteredJockeys"
                    field="label"
                    @complete="searchJockey"
                  />
                </template>
              </Column>
              <Column header="WAdj">
                {{
                  slotProps.data.weight_carried - slotProps.data.allocatedWeight
                }}
              </Column>
              <Column field="allocatedWeight" header="AWt">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="weight_carried" header="CWt">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_settling_down" header="SD">
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_m1200" header="1200">
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_m800" header="800">
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="position_m400" header="400">
                <template #editor="{ data, field }">
                  <InputNumber v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="margin" header="Margin">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="tinyInput" />
                </template>
              </Column>
              <Column field="price_opening" header="Open">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column field="price_mid" header="Fl 1">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <!-- <Column field="price_mid_2" header="Fl 2">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput"/>
                </template>
              </Column> -->
              <Column field="price_start" header="Start">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column field="prizemoney_race" header="PMy" :sortable="true">
                <template #editor="{ data, field }">
                  <InputNumber
                    v-model="data[field]"
                    :maxFractionDigits="0"
                    class="smallInputCurrency"
                    currency="AUD"
                    locale="en-AU"
                    mode="currency"
                  />
                </template>
                <template #body="{ data, field }">
                  {{ formatCurrency(data[field]) }}
                </template>
              </Column>
              <Column field="prizemoney_bonus" header="Bon" :sortable="true">
                <template #editor="{ data, field }">
                  <InputNumber
                    v-model="data[field]"
                    :maxFractionDigits="0"
                    class="smallInputCurrency"
                    currency="AUD"
                    locale="en-AU"
                    mode="currency"
                  />
                </template>
                <template #body="{ data, field }">
                  {{ formatCurrency(data[field]) }}
                </template>
              </Column>
              <Column :rowEditor="true" bodyStyle="text-align:center"></Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    class="p-button-rounded p-button-danger p-button-text"
                    icon="pi pi-times"
                    @click="console.log(slotProps.data)"
                  />
                </template>
              </Column>
              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse p-scratched-horse-sml"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel v-if="race.horses" header="Owners">
            <DataTable
              v-model:editingRows="editingRowsOwners"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="cell"
              responsiveLayout="scroll"
              @cell-edit-complete="onCellEditSaveOwners($event, race)"
            >
              <Column field="tab_number" header="TabNo" :sortable="true">
              </Column>
              <Column field="@_name" header="Horse" style="width: 200px">
              </Column>
              <Column field="owners" header="Owners">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="fullInput" />
                </template>
              </Column>
              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse p-scratched-horse-sml"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel v-if="race.horses" header="Betting">
            <div class="formgrid grid justify-content-end">
              <div class="v-col-2" v-if="user_role < 4">
                <Dropdown
                  id="raceMarketConfidence"
                  v-model="race.confidence"
                  :options="[
                    { value: 8, label: '1. Very Low' },
                    { value: 7, label: '2. Low' },
                    { value: 6, label: '3. Poor' },
                    { value: 5, label: '4. Average' },
                    { value: 4, label: '5. Good' },
                    { value: 3, label: '6. High' },
                    { value: 2, label: '7. Very High' },
                  ]"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select Market confidence"
                >
                </Dropdown>
              </div>
              <div class="v-col-1" v-if="user_role < 4">
                <Button
                  icon="pi pi-dollar"
                  class="p-button-rounded p-button-primary mr-3"
                  @click.stop="enterMarket(race, race.confidence)"
                />
              </div>
              <div class="v-col-4">
                <b>target pct:&nbsp; </b>
                {{ (race.horses.horse.length + 120).toString() }}<br />
                <b>total pct:&nbsp; </b> {{ race.raceTotalPct }}
              </div>
            </div>
            <DataTable
              v-model:editingRows="editingRowsBetting"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="cell"
              responsiveLayout="scroll"
              @cell-edit-complete="onCellEditSaveBetting($event, race)"
            >
              <Column
                field="tab_number"
                header="TabNo"
                :sortable="true"
                class="col-1"
              >
              </Column>
              <Column
                field="@_name"
                header="Horse"
                :sortable="true"
                class="col-2"
              >
              </Column>
              <Column field="totalWeight" header="Weight" class="col-1">
              </Column>
              <Column field="barrier" header="Barrier" class="col-1"> </Column>
              <Column
                field="rating"
                header="Rating"
                :sortable="true"
                class="col-1"
              >
              </Column>
              <Column
                field="betting"
                header="Betting"
                style="width: 200px"
                @focusout="cell - edit - complete"
                :sortable="true"
                class="col-1"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="smallInput" />
                </template>
              </Column>
              <Column
                field="betting_pct"
                header="%"
                :sortable="true"
                class="col-1"
              >
              </Column>
              <Column key="tip" field="tip" header="Tips" class="col-1">
                <template #body="slotProps">
                  <button
                    class="p-tip-button"
                    @click.stop="toggleTip(slotProps.data, race)"
                  >
                    <div style="">&nbsp;{{ slotProps.data.tip }}&nbsp;</div>
                  </button>
                </template>
              </Column>
              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse p-scratched-horse-sml"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel v-if="race.horses" header="Gear Changes">
            <!--            <th class="text-left">Horse Name</th>
                        <th class="text-left">Weight</th>
                        <th class="text-left">Barrier</th>
                        <th class="text-left">Betting</th>
                        <th class="text-left">%</th>
                        <th class="text-left">Tips</th>   -->
            <DataTable
              v-model:editingRows="editingRowsGearChanges"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="cell"
              responsiveLayout="scroll"
              @cell-edit-complete="onCellEditSaveGearChanges($event, race)"
            >
              <Column
                field="tab_number"
                header="TabNo"
                :sortable="true"
                class="col-1"
              >
              </Column>
              <Column
                field="@_name"
                header="Horse"
                style="width: 200px"
                class="col-2"
              >
              </Column>
              <Column
                field="@_sex"
                header="Sex"
                style="width: 100px"
                class="col-1"
              >
              </Column>
              <Column field="gear" header="Gear Changes">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="fullInput" />
                </template>
              </Column>
              <Column field="ran_gear" header="Running gear">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="fullInput" />
                </template>
              </Column>
              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse p-scratched-horse-sml"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel v-if="race.horses" header="Comment">
            <DataTable
              v-model:editingRows="editingRowsComment"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="cell"
              responsiveLayout="scroll"
              @cell-edit-complete="onCellEditSaveComment($event, race)"
            >
              <Column
                field="tab_number"
                header="TabNo"
                :sortable="true"
                class="col-1"
              >
              </Column>
              <Column
                field="@_name"
                header="Horse"
                style="width: 200px"
                class="col-2"
              >
              </Column>
              <Column
                field="betting"
                header="Betting"
                style="width: 100px"
                class="col-1"
              >
              </Column>
              <Column field="rating" header="Rating" class="col-1">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column field="comment" header="Comment">
                <template #editor="{ data, field }">
                  <Textarea
                    v-model="data[field]"
                    class="fullInput"
                    autoResize
                  />
                </template>
              </Column>
              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse p-scratched-horse-sml"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel v-if="race.horses" header="Stewards Reports">
            <DataTable
              v-model:editingRows="editingRowsStewards"
              :value="race.horses.horse"
              class="p-datatable-sm"
              dataKey="@_id"
              editMode="cell"
              responsiveLayout="scroll"
              showGridlines
              @cell-edit-complete="onCellEditSaveStewards($event, race)"
            >
              <Column
                field="tab_number"
                header="TabNo"
                :sortable="true"
                class="col-1"
              >
              </Column>
              <Column
                field="@_name"
                header="Horse"
                :sortable="true"
                style="width: 200px"
                class="col-3"
              >
              </Column>

              <Column
                field="stewards_report_1"
                header="1"
                class="col-1"
                @focusout="cell - edit - complete"
              >
                <template #editor="{ data, field }">
                  <InputText
                    v-model="data[field]"
                    class="smallInput stewInput"
                    @focusin="selectThis($event)"
                  />
                </template>
              </Column>
              <Column
                field="stewards_report_2"
                header="2"
                class="col-1"
                @focusout="cell - edit - complete"
              >
                <template #editor="{ data, field }">
                  <InputText
                    v-model="data[field]"
                    class="smallInput stewInput"
                    @focusin="selectThis($event)"
                  />
                </template>
              </Column>
              <Column
                field="stewards_report_3"
                header="3"
                class="col-1"
                @focusout="cell - edit - complete"
              >
                <template #editor="{ data, field }">
                  <InputText
                    v-model="data[field]"
                    class="smallInput stewInput"
                    @focusin="selectThis($event)"
                  />
                </template>
              </Column>
              <Column
                field="stewards_report_4"
                header="4"
                class="col-1"
                @focusout="cell - edit - complete"
              >
                <template #editor="{ data, field }">
                  <InputText
                    v-model="data[field]"
                    class="smallInput stewInput"
                    @focusin="selectThis($event)"
                  />
                </template>
              </Column>
              <Column
                field="stewards_report_5"
                header="5"
                class="col-1"
                @focusout="cell - edit - complete"
              >
                <template #editor="{ data, field }">
                  <InputText
                    v-model="data[field]"
                    class="smallInput stewInput"
                    @focusin="selectThis($event)"
                  />
                </template>
              </Column>
              <Column field="stewards_report_string" header="" class="col">
              </Column>

              <Column field="scratching" style="font-size: 0">
                <template #body="slotProps">
                  <p
                    class="p-scratched-horse p-scratched-horse-sml"
                    :style="slotProps.data.scratched ? '' : 'display:none;'"
                  >
                    SCRATCHED
                  </p>
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <!-- <TabPanel v-if="race.horses" header="Stewards Reports Text">
            <DataTable v-model:editingRows="editingRowsStewards"
                       :value="race.horses.horse"
                       class="p-datatable-sm"
                       dataKey="@_id"
                       editMode="cell"
                       responsiveLayout="scroll"
                       @cell-edit-complete="onCellEditSaveStewards($event, race)">
              <Column field="tab_number" header="TabNo" :sortable="true" class="col-1">
              </Column>
              <Column field="@_name" header="Horse"  style="width:200px" class="col-3">
              </Column>
              
              <Column field="stewards_report_string" header="" class="col-6">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" class="fullInput"/>
                 
                </template>
              </Column>
              
              
              <Column field="scratching" style="font-size:0;" >
                <template #body="slotProps">
                  <p class="p-scratched-horse p-scratched-horse-sml" :style="(slotProps.data.scratched) ? '' : 'display:none;'">SCRATCHED</p>
                </template>
              </Column>
            </DataTable>
          </TabPanel> -->
        </TabView>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import { API } from "aws-amplify";

import moment from "moment";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AutoComplete from "primevue/autocomplete";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import Textarea from "primevue/textarea";
import Checkbox from "primevue/checkbox";

export default {
  components: {
    Accordion,
    AccordionTab,
    TabView,
    TabPanel,
    DataTable,
    Column,
    InputText,
    InputNumber,
    Dropdown,
    Dialog,
    Button,
    AutoComplete,
    Textarea,
    Checkbox,
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => ({
    showDeleteConfirmDialog: false,
    raceToDelete: null,
    meeting_errors: [],
    races: [],
    meetingDetails: [],
    editingRowsRace: [],
    editingRowsFields: [],
    editingRowsBetting: [],
    editingRowsGearChanges: [],
    editingRowsComment: [],
    editingRowsOwners: [],
    editingRowsStewards: [],
    editingRowsResults: [],
    formResultsData: [],
    enabledRaceFields: [],
    errors: [],
    filteredTrainers: null,
    filteredJockeys: null,
    filteredClasses: null,
    filteredTracks: null,
    filteredMargins: null,
    selectedRace: null,
    errorSummary: null,
    showEditRaceDialog: false,
    showErrorDialog: false,
    tab: null,
    selectedEntity: { race: {}, horse: {}, type: "" },
    loader: Boolean,
    activeIndex: null,
    fieldSort: [
      { field: "tab_number", order: 1 },
      { field: "allocatedWeight", order: -1 },
    ],
    resultsSort: [
      { field: "finish_position", order: 1 },
      { field: "prizemoney_race", order: -1 },
    ],
    race_types: [
      { value: "Flat", label: "Flat" },
      { value: "Jumps", label: "Jumps" },
    ],
    race_stages: [
      { value: "Nominations", label: "Nominations" },
      { value: "Weights", label: "Weights" },
      { value: "Acceptances", label: "Acceptances" },
      { value: "Results", label: "Results" },
      { value: "Abandoned", label: "Abandoned" },
    ],
    weight_types: [
      { value: "Handicap", label: "Handicap" },
      { value: "Weight For Age", label: "Weight For Age" },
      {
        value: "Weight For Age With Penalties",
        label: "Weight For Age With Penalties",
      },
      { value: "Set Weight", label: "Set Weight" },
      {
        value: "Set Weight With Penalties",
        label: "Set Weight With Penalties",
      },
      {
        value: "Set Weight With Penalties and Allowances",
        label: "Set Weight With Penalties and Allowances",
      },
      { value: "Special Weight", label: "Special Weight" },
      { value: "Catch Weight", label: "Catch Weight" },
      { value: "Fly Weight", label: "Fly Weight" },
      {
        value: "Set Weight With Allowances",
        label: "Set Weight With Allowances",
      },
      { value: "Quality", label: "Quality" },
    ],
    official_margins: [
      { value: "NS", label: "NS" },
      { value: "SHH", label: "SHH" },
      { value: "HH", label: "HH" },
      { value: "SH", label: "SH" },
      { value: "HD", label: "HD" },
      { value: "LH", label: "LH" },
      { value: "HN", label: "HN" },
      { value: "SN", label: "SN" },
      { value: "NK", label: "NK" },
      { value: "LN", label: "LN" },
      { value: "DH", label: "DH" },
    ],
    tipsValues: Array.from({ length: 4 }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    })),
    stewards_options_1: {
      "#": "Tightened for room",
      $: "Drifted back",
      "(": "Bucked",
      "/": "Disappointed for a run",
      1: "Struck interference",
      2: "Blundered",
      3: "Checked",
      4: "Eased",
      5: "Shifted out",
      6: "Carried wide",
      7: "Shifted in",
      8: "Hit rail",
      9: "Squeezed out",
      "=": "Crowded",
      "@": "Awkwardly placed",
      "^": "Restrained",
      A: "Shied",
      B: "Wide",
      I: "Blocked for run",
      J: "Eased",
      K: "Inconvenienced",
      M: "Laid in",
      N: "Laid out",
      O: "Hung in",
      P: "Hung out",
      Q: "Severely hampered",
      R: "Hampered",
      X: "Held up",
      Y: "Overraced",
      Z: "Weakened",
    },
    stewards_options_2: {
      "!": "near 50m ",
      "#": "near 250m ",
      $: "near 300m ",
      "%": "several times ",
      "&": "near 500m ",
      ")": "Near 900m",
      "*": "Near 700m",
      0: "during race",
      1: "near 100m ",
      2: "near 200m ",
      3: "near 400m ",
      4: "near 600m ",
      5: "near 800m ",
      6: "near 1000m ",
      7: "near 1200m ",
      8: "near 1400m ",
      9: "near 1600m ",
      "@": "near 150m ",
      A: "near 2000m ",
      B: "early on ",
      C: "near turn ",
      D: "in straight",
      E: "near post ",
      F: "at start ",
      G: "at crossing",
      H: "early stages ",
      J: "early, middle stages ",
      K: "concluding stages ",
      L: "on straightening",
      M: "middle stages ",
      T: "throughout ",
    },
    stewards_options_3: {
      "1N": "Bled from one nostril",
      "1P": "One paced",
      "2P": "Second - positive swab",
      "3P": "Third - positive swab",
      "3W": "Raced three wide",
      "4W": "Raced four wide",
      "5W": "Raced five wide",
      "B=": "Began awkwardly and lost ground",
      BO: "Choked down",
      BR: "Bumped rival",
      BS: "Bounded on jumping",
      BV: "Brushed rail",
      BX: "Struck barrier",
      C0: " ",
      C1: " ",
      C2: " ",
      C3: " ",
      C4: " ",
      C5: "Lost Ground",
      C6: " ",
      C7: "Raced Greenly",
      C8: "Broke Down",
      C9: "Disappointed for a run",
      CA: "Blocked for run",
      CB: " ",
      CC: "Pulled hard",
      CD: "Ran wide at turn",
      CE: "Hung in during race",
      CF: "Hung out during race",
      CG: "Ran off on turn",
      CH: "Clipped heels",
      CO: "Coughing",
      D0: " ",
      D1: "Rider's whip entangled",
      D2: " ",
      D3: "Reared at start",
      D4: "Left at start",
      D5: "Became unbalanced",
      D6: "Bucked at start",
      D7: "Began awkwardly",
      D8: "Tongue over bit",
      D9: "Failed due to track condition",
      DA: "Caused interference",
      DB: "Fractious in stalls",
      DC: "Backed on bar as gates opened",
      DD: "Swung sideways at start",
      DE: "Raced erratically",
      DF: "Slowly away",
      DI: "Ducked in",
      DO: "Ducked out",
      DP: "Promoted due to disqualification",
      DR: "Rider dropped rein",
      DV: "Distressed",
      E0: "Rider lost iron",
      E1: "Rider lost whip",
      E2: "Saddle slipped",
      E3: "Vet examination",
      E4: "Shifted ground",
      E5: "Head in adjoining stall",
      E6: "Struck head on barrier",
      E7: "Galloped on",
      E8: "Gear broke",
      E9: "Struck with another's whip",
      EA: "Bled",
      EB: "Fell",
      EC: "Lame",
      ED: "Injured",
      EE: "Lost a plate",
      EF: "Twisted a plate",
      EI: "Suffered Exercise-Induced Pulmonary Haemorrhage ",
      EJ: "Eased down",
      F0: " ",
      F1: " ",
      F2: "Ordered to trial",
      F3: "Shied at crossing",
      F4: "Rider told to use more care",
      F5: "Tightened for room",
      F6: "Swab taken by order of stewards",
      F7: "Inquiry into performance",
      F8: "Rider told to make more effort to improve position",
      F9: "Rider told to use more vigour",
      FA: "Rider charged with careless riding",
      FB: "Protest lodged, upheld",
      FC: "Protest lodged, dismissed",
      FD: "Protested against, upheld",
      FE: "Protested against, dismissed",
      FF: "Read full report",
      FG: "Failed to handle going",
      FI: "Floating incident - passed fit",
      FR: "Raced flat",
      FS: "Broke through barriers, cleared to start",
      GA: "Proved difficult to ride out",
      GB: "Difficult to load",
      GC: "Passed fit at barrier",
      GD: "Stewards queried run",
      GF: "Impeded by a fallen horse",
      GI: "Gear issue",
      HH: "Contacted hurdle",
      HS: "Contacted steeple",
      HU: "Got its head up",
      II: "Eye injury",
      KR: "Keen",
      L1: "Lame 1/5",
      L2: "Lame 2/5",
      L3: "Lame 3/5",
      L4: "Lame 4/5",
      L5: "Lame 5/5",
      "L=": "Loading incident - passed fit",
      LI: "Rider lost both irons",
      LP: "Lost plates",
      MI: "Minor injury",
      MS: "Muscle strain",
      MY: "Mounting yard incident - passed fit",
      NI: "Mouth injury",
      NR: "Failed to respond to riding",
      NS: "Replated at barriers",
      NX: "Not fully tested",
      PP: "Raced below expectations",
      RP: "Reluctant to proceed to barriers",
      RR: "Resented racing between runners",
      RS: "Sore",
      RW: "Rider concerned with action",
      S1: "Slowly away (1L)",
      S2: "Dwelt at start (2L)",
      S3: "Broke poorly (3L)",
      S4: "Commenced slowly (4L)",
      S5: "Blew start (5L)",
      S6: "Blew start (6L)",
      SL: "Second - rider weighed in light",
      SS: "Slipped at start",
      SX: "Bombed the start (7L+)",
      T1: "Fractious in barriers",
      TA: "Tempo against",
      TB: "Taken back from wide barrier",
      TF: "Too firm",
      TI: "Tendon injury",
      TL: "Third - rider weighed in light",
      TO: "Tailed off",
      TP: "Travelled poorly",
      TQ: "Tactics queried",
      TS: "Travelled well",
      TW: "Too wet",
      U1: "Pre race incident",
      U2: "Shin sore",
      U3: "Warning issued",
      U4: "Vet Certificate required",
      UL: "Unplaced - rider weighed in light",
      UP: "Under pressure turn",
      V0: "Bled second time - life time ban",
      V1: "Vetted - no abnormalities",
      V2: "Vetted - no abnormalities, ordered to trial",
      V3: "Cardiac arrhythmia",
      V4: "Elevated heart rate",
      V5: "Respiratory issues",
      V6: "Lacerations",
      V7: "In season",
      V8: "Poor recovery",
      V9: "Bled first time - three month ban",
      VA: "Heat stress",
      VB: "Blood in trachea",
      VC: "Irregular heart rate",
      VD: "Thumps",
      VE: "Tied up",
      W1: "Warning - barrier manners",
      W2: "Warning - uncompetitive",
      W3: "Warning - racing manners",
      WC: "Whip breach - rider charged",
      WL: "Winner - rider weighed in light",
      WN: "Weakened noticeably",
      WP: "Winner - positive swab",
      WR: "Whip breach - relegated",
      WS: "Wayward under pressure in straight",
      WT: "Wide throughout",
      WW: "Raced wide with cover",
      WY: "Raced wide without cover",
      XP: "Unplaced - positive swab",
    },
    condition_options: [
      { value: "Firm(1)", label: "Firm(1)" },
      { value: "Firm(2)", label: "Firm(2)" },
      { value: "Good(3)", label: "Good(3)" },
      { value: "Good(4)", label: "Good(4)" },
      { value: "Soft(5)", label: "Soft(5)" },
      { value: "Soft(6)", label: "Soft(6)" },
      { value: "Soft(7)", label: "Soft(7)" },
      { value: "Heavy(8)", label: "Heavy(8)" },
      { value: "Heavy(9)", label: "Heavy(9)" },
      { value: "Heavy(10)", label: "Heavy(10)" },
      { value: "Heavy(11)", label: "Heavy(11)" },
      { value: "Synthetic", label: "Synthetic" },
      { value: "Firm", label: "Firm" },
      { value: "Good", label: "Good" },
      { value: "Soft", label: "Soft" },
      { value: "Heavy", label: "Heavy" },
      { value: "Fast", label: "Fast" },
      { value: "Dirt", label: "Dirt" },
      { value: "Sand", label: "Sand" },
      { value: "Dead", label: "Dead" },
      { value: "Slow", label: "Slow" },
    ],
    jockeyrest_options: [
      { label: "Apprentices Can Claim", value: "Apprentices Can Claim" },
      { label: "Apprentice Riders Only", value: "Apprentice Riders Only" },
      {
        label: "Eligible Riders Can Claim",
        value: "Eligible Riders Can Claim",
      },
      { label: "Gentlemen Riders Only", value: "Gentlemen Riders Only" },
      { label: "Invited Riders", value: "Invited Riders" },
      { label: "Lady Riders Only", value: "Lady Riders Only" },
      { label: "Apprentices Cannot Claim", value: "Apprentices Cannot Claim" },
      {
        label: "Eligible Riders Cannot Claim",
        value: "Eligible Riders Cannot Claim",
      },
      { label: "Amateur Riders", value: "Amateur Riders" },
      { label: "Hurdle Jockeys Only", value: "Hurdle Jockeys Only" },
    ],
    agerest_options: [
      { label: "", value: "" },
      { label: "2yo", value: "2yo" },
      { label: "2yo+", value: "2yo+" },
      { label: "2,3,4yo", value: "2,3,4yo" },
      { label: "3yo", value: "3yo" },
      { label: "3yo+", value: "3yo+" },
      { label: "4yo", value: "4yo" },
      { label: "4yo+", value: "4yo+" },
      { label: "5yo", value: "5yo" },
      { label: "5yo+", value: "5yo+" },
      { label: "6yo", value: "6yo" },
      { label: "Aged", value: "Aged" },
      { label: "2,3yo", value: "2,3yo" },
      { label: "3,4yo", value: "3,4yo" },
      { label: "3,4,5yo", value: "3,4,5yo" },
      { label: "4,5yo", value: "4,5yo" },
      { label: "4,5,6yo", value: "4,5,6yo" },
      { label: "3,4,5,6", value: "3,4,5,6" },
    ],
    sexrest_options: [
      { label: "", value: "" },
      { label: "Fillies", value: "Fillies" },
      { label: "Mares", value: "Mares" },
      { label: "Colts", value: "Colts" },
      { label: "Geldings", value: "Geldings" },
      { label: "Horses", value: "Horses" },
      { label: "Fillies & Mares", value: "Fillies & Mares" },
      { label: "Colts & Horses", value: "Colts & Horses" },
      { label: "Colts & Geldings", value: "Colts & Geldings" },
      { label: "Horses & Geldings", value: "Horses & Geldings" },
      { label: "Horses & Mares", value: "Horses & Mares" },
      { label: "Colts & Fillies", value: "Colts & Fillies" },
      { label: "Colts, Horses & Geldings", value: "Colts, Horses & Geldings" },
      {
        label: "Fillies, Colts & Geldings",
        value: "Fillies, Colts & Geldings",
      },
      { label: "Mares, Horses & Geldings", value: "Mares, Horses & Geldings" },
      {
        label: "Fillies, Mares, Colts & Horses",
        value: "Fillies, Mares, Colts & Horses",
      },
      {
        label: "Fillies, Mares, Colts & Geldings",
        value: "Fillies, Mares, Colts & Geldings",
      },
    ],
  }),

  beforeCreate() {
    console.log("At this point, events and lifecycle have been initialized.");
    //this.loader=this.loading;
  },
  watch: {
    loading(val) {
      console.log("loading");
      console.log(val);
    },
  },
  async created() {
    console.log("MeetingNominations child watching");
    console.log(this.loading);
    console.log("Loading races for meeting id: " + this.meetingId);
    this.fetchMeetingDetails(this.meetingId).then(() => {
      console.log(this.meetingDetails);
    });
    // this.current_user = await Auth.currentUserInfo()
    console.log(this.current_user);
    console.log(this.$props.current_user);
    console.log(this.user_role);
    console.log(this.$props.user_role);
  },

  props: {
    meetingId: null,
    meetingLocked: null,
    loading: Boolean,
    commonEntities: null,
    current_user: null,
    user_role: null,
  },
  methods: {
    searchTrainer(event) {
      this.filteredTrainers = this.commonEntities.allTrainers.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    confirmDeleteRace(race) {
      this.raceToDelete = race;
      this.showDeleteConfirmDialog = true;
    },
    async deleteRaceConfirmed() {
      if (this.raceToDelete) {
        await this.deleteRace(this.raceToDelete);
        this.showDeleteConfirmDialog = false;
        this.raceToDelete = null;
      }
    },
    async deleteRace(race) {
      console.log(JSON.stringify(this.meetingId));
      try {
        await API.put("MrCenApiGateway", `/processedmeetings`, {
          body: {
            user: this.current_user.username,
            level: "processed_race",
            meetingId: this.meetingId,
            delete_race: race["@_number"],
          },
        })
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary:
                "The race " + race["@_name"] + " was successfully deleted.",
              life: 5000,
            });
            this.fetchMeetingDetails(this.meetingId).then(() => {
              console.log(this.meetingDetails);
            });
            //reloads after update
            // this.fetchMeetings(this.selectedDate);
          })
          .catch((error) => {
            alert(
              "An error occurred when trying to delete this race: " +
                error.toString()
            );
          });
      } catch (error) {
        console.log("races", error);
      }
    },
    searchJockey(event) {
      if (
        !event.query ||
        !event.query.trim() ||
        event.query.replace(" ", "") == ""
      )
        return { label: "", value: "" };
      this.filteredJockeys = this.commonEntities.allJockeys.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchTrack(event) {
      this.filteredTracks = this.commonEntities.allTracks.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchClass(event) {
      this.filteredClasses = this.commonEntities.allClasses.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchMargin(event) {
      this.filteredMargins = this.official_margins.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    getTime(date) {
      return moment(date, "HHmm").format("h:mm a");
    },
    async clearErrors(entity) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      if (entity.type == "horse") {
        entity.horse.errors = [];
        return await API.put("MrCenApiGateway", `/processedmeetings`, {
          body: {
            level: "processed_horse",
            errors: [],
            meetingId: this.$props.meetingId,
            raceId: entity.race["@_id"],
            horseId: entity.horse["@_id"],
            user: this.current_user.username,
          },
        })
          .then((response) => {
            console.log(response);
            this.$toast.add({
              severity: "success",
              summary: "Field updated.",
              life: 2000,
            });
            entity.race.horses.horse[
              entity.race.horses.horse.indexOf(
                entity.race.horses.horse.filter((h) => {
                  return h["@_id"] === entity.horse["@_id"];
                })[0]
              )
            ] = entity.horse;
          })
          .catch((error) => {
            alert(
              "An error occurred when trying to update this meeting: " +
                error.toString()
            );
          });
      } else if (entity.type == "race") {
        entity.race.errors = [];
        await API.put("MrCenApiGateway", `/processedmeetings`, {
          body: {
            level: "processed_race",
            meetingId: this.$props.meetingId,
            raceId: entity.race["@_id"],
            errors: [],
            user: this.current_user.username,
          },
        })
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary:
                "The race " +
                entity.race["@_name"] +
                " was successfully updated.",
              life: 5000,
            });
            //reloads after update
            // this.fetchMeetings(this.selectedDate);
          })
          .catch((error) => {
            alert(
              "An error occurred when trying to approve this race: " +
                error.toString()
            );
          });
      }
    },
    convertObject(object, key) {
      return object[key];
    },
    async onRowEditSaveFields(event, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(event);
      let { newData, index } = event;
      console.log(index);
      let body = newData;
      body.user = this.current_user.username;
      body.meetingId = this.$props.meetingId;
      body.raceId = race["@_id"];
      body.horseId = event.newData["@_id"];
      body.level = "processed_horse";
      body.totalWeight = parseFloat(newData.totalWeight);
      body.allocatedWeight = parseFloat(newData.allocatedWeight);
      if (
        !body.jockeyName ||
        body.jockeyName.label == "" ||
        (!body.jockeyName.label && !body.jockeyName.trim())
      ) {
        body.jockeyId = "";
        body.jockeyName = {
          label: "",
          value: "",
        };
      } else if (body.jockeyName.label === undefined) {
        body.jockeyName = {
          label: body.jockeyName,
          value: body.jockeyId,
        };
      }
      console.log(body);
      await API.put("MrCenApiGateway", `/processedmeetings`, { body: body })
        .then(async () => {
          //await this.fetchMeetingDetails(this.meetingId);
          if (newData.trainerName.label !== undefined)
            newData.trainerName = newData.trainerName.label;
          if (newData.jockeyName.label !== undefined)
            newData.jockeyName = newData.jockeyName.label;
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === newData["@_id"];
              })[0]
            )
          ] = newData;
          this.$toast.add({
            severity: "success",
            summary: "Row updated.",
            life: 2000,
          });
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async updateRace() {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      if (
        this.offMarginIsBad(this.selectedRace.official_margin_1) ||
        this.offMarginIsBad(this.selectedRace.official_margin_2)
      ) {
        alert(
          "Please double check the official_margins, they need to match the dropdown text, or be a valid number/decimal"
        );
        return "";
      }
      console.log(this.selectedRace);
      var sectional = this.selectedRace.sectional;
      if (this.selectedRace.sec_timing && this.selectedRace.sec_distance) {
        sectional = {
          "@_distance": this.selectedRace.sec_distance,
          "@_time": this.selectedRace.sec_timing,
          "@_location": this.selectedRace.sec_indicator,
        };
      }
      var restrictions = this.selectedRace.restrictions;
      // if (this.selectedRace.jockey_rest || this.selectedRace.age_rest || this.selectedRace.sex_rest){
      //   restrictions = {}
      //   if (this.selectedRace.age_rest){
      //     restrictions["@_age"] = this.selectedRace.age_rest
      //   }
      //   if (this.selectedRace.sex_rest){
      //     restrictions["@_sex"] = this.selectedRace.sex_rest
      //   }
      //   if (this.selectedRace.jockey_rest){
      //     restrictions["@_jockey"] = this.selectedRace.jockey_rest
      //   }

      // }
      if (typeof this.selectedRace.official_margin_1 === "object") {
        this.selectedRace.official_margin_1 = this.convertObject(
          this.selectedRace.official_margin_1,
          "value"
        );
      }
      if (typeof this.selectedRace.official_margin_2 === "object") {
        this.selectedRace.official_margin_2 = this.convertObject(
          this.selectedRace.official_margin_2,
          "value"
        );
      }

      var dataToUpdate = {
        level: "processed_race",
        user: this.current_user.username,
        meetingId: this.$props.meetingId,
        raceId: this.selectedRace["@_id"],
        raceName: this.selectedRace["@_name"],
        raceNameShort: this.selectedRace["@_shortname"],
        "@_name": this.selectedRace["@_name"],
        "@_shortname": this.selectedRace["@_shortname"],
        race_stage: this.selectedRace.race_stage,
        distance: this.selectedRace.distance,
        restrictions: restrictions,
        apprentice_claim: this.selectedRace.apprentice_claim,
        weight_type: this.selectedRace.weight_type,
        min_hcp_weight: this.selectedRace.min_hcp_weight,
        start_time: this.selectedRace.start_time,
        race_type: this.selectedRace.race_type,
        prizes: this.selectedRace.prizes,
        duration: this.selectedRace.duration,
        official_margin_1: this.selectedRace.official_margin_1,
        official_margin_2: this.selectedRace.official_margin_2,
        sectional: sectional,
        timing_method: this.selectedRace.timing_method,
        track_condition: this.selectedRace.track_condition ?? "",
        track:
          this.selectedRace.track_name.label ?? this.selectedRace.track_name,
        classes: this.selectedRace.classes,
        starters: this.selectedRace.starters,
      };
      if (this.selectedRace.class.value) {
        dataToUpdate.classes.class = this.selectedRace.class.label;
        dataToUpdate.classes.class_id = this.selectedRace.class.value;
        this.selectedRace.class = this.selectedRace.class.label;
      }
      if (this.selectedRace.second_class.value) {
        dataToUpdate.classes.second_class =
          this.selectedRace.second_class.label;
        dataToUpdate.classes.second_class_id =
          this.selectedRace.second_class.value;
        this.selectedRace.second_class = this.selectedRace.second_class.label;
      }
      if (this.selectedRace.third_class.value) {
        dataToUpdate.classes.third_class = this.selectedRace.third_class.label;
        dataToUpdate.classes.third_class_id =
          this.selectedRace.third_class.value;
        this.selectedRace.third_class = this.selectedRace.third_class.label;
      }
      if (this.selectedRace.group) {
        dataToUpdate.group = this.selectedRace.group;
      }
      if (this.selectedRace.updateMarket) {
        dataToUpdate.updateMarket = true;
        dataToUpdate.confidence = this.selectedRace.confidence;
        dataToUpdate.horses = {};
        dataToUpdate.horses.horse = this.selectedRace.horses.horse;
      }
      if (this.selectedRace.single_horse) {
        dataToUpdate.single_horse = this.selectedRace.single_horse;
      }
      await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: dataToUpdate,
      })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary:
              "The race " +
              this.selectedRace["@_name"] +
              " was successfully updated.",
            life: 5000,
          });
          //reloads after update
          // this.fetchMeetings(this.selectedDate);
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to approve this race: " +
              error.toString()
          );
        });

      this.showEditRaceDialog = false;
    },
    async onCellEditSaveBetting(event, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(event);
      if (event.newData === undefined) {
        return false;
      }
      let formUpdateData = {
        level: "processed_horse",
        meetingId: this.$props.meetingId,
        raceId: race["@_id"],
        horseId: event.newData["@_id"],
        user: this.current_user.username,
      };
      if (event.newData.betting) {
        formUpdateData.betting = event.newData.betting;
        event.newData.betting_pct =
          Math.round(2 * (100 / event.newData.betting)) / 2;
      }
      console.log(event.newData.betting_pct);
      return await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: formUpdateData,
      })
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Field updated.",
            life: 2000,
          });
          race.raceTotalPct = 0;

          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === event.newData["@_id"];
              })[0]
            )
          ] = event.newData;
          for (var the_horse of race.horses.horse) {
            if (the_horse.betting) {
              race.raceTotalPct = race.raceTotalPct + the_horse.betting_pct;
            }
          }
          console.log(race.raceTotalPct);
          // console.log(race)
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async toggleScratching(horse, race) {
      console.log("from toggle scratching");
      console.log(horse);
      console.log(race);
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(horse);
      console.log(race);
      var scratching = true;
      if (horse.scratched) {
        scratching = false;
      }
      return await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_horse",
          meetingId: this.$props.meetingId,
          raceId: race["@_id"],
          horseId: horse["@_id"],
          scratched: scratching,
          user: this.current_user.username,
        },
      })
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Scratching status updated.",
            life: 2000,
          });
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === horse["@_id"];
              })[0]
            )
          ] = horse;
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async toggleTip(horse, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(horse);
      console.log(race);
      var tip = 0;
      if (horse.tip != "" && horse.tip != 0) {
        horse.tip = "";
        tip = "";
      } else {
        var tipArray = [];
        for (const otherhorse of race.horses.horse) {
          if (otherhorse.tip && otherhorse.tip != 0) {
            tipArray.push(otherhorse.tip);
          }
        }
        if (!tipArray.includes(1)) {
          horse.tip = 1;
          tip = 1;
        } else if (!tipArray.includes(2)) {
          horse.tip = 2;
          tip = 2;
        } else if (!tipArray.includes(3)) {
          horse.tip = 3;
          tip = 3;
        } else if (!tipArray.includes(4)) {
          horse.tip = 4;
          tip = 4;
        } else {
          this.$toast.add({
            severity: "alert",
            summary: "Four tips already selected",
            life: 1500,
          });
          return false;
        }
      }
      return await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_horse",
          meetingId: this.$props.meetingId,
          raceId: race["@_id"],
          horseId: horse["@_id"],
          tip: tip,
          user: this.current_user.username,
        },
      })
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Tips updated.",
            life: 2000,
          });
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === horse["@_id"];
              })[0]
            )
          ] = horse;
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async onCellEditSaveGearChanges(event, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(event);

      return await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_horse",
          gear_changes: event.newData.gear,
          running_gear: { gear_item: event.newData.ran_gear.split(";") },
          meetingId: this.$props.meetingId,
          raceId: race["@_id"],
          horseId: event.newData["@_id"],
          user: this.current_user.username,
        },
      })
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Field updated.",
            life: 2000,
          });
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === event.newData["@_id"];
              })[0]
            )
          ] = event.newData;
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async onCellEditSaveComment(event, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(event);

      return await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_horse",
          comment: event.newData.comment,
          rating: event.newData.rating ?? 0,
          meetingId: this.$props.meetingId,
          raceId: race["@_id"],
          horseId: event.newData["@_id"],
          user: this.current_user.username,
        },
      })
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Field updated.",
            life: 2000,
          });
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === event.newData["@_id"];
              })[0]
            )
          ] = event.newData;
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async onCellEditSaveOwners(event, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(event);

      return await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_horse",
          owners: event.newData.owners,
          meetingId: this.$props.meetingId,
          raceId: race["@_id"],
          horseId: event.newData["@_id"],
          user: this.current_user.username,
        },
      })
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Field updated.",
            life: 2000,
          });
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === event.newData["@_id"];
              })[0]
            )
          ] = event.newData;
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async onCellEditSaveStewards(event, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log("pushing stewards");
      console.log(event);
      var stewards_report = [];
      event.newData.stewards_report_string = "";
      for (var i = 1; i < 6; i++) {
        if (event.field == "stewards_report_" + i) {
          event.newData["stewards_report_" + i] = event.newValue.toUpperCase();
          event.newData.stewards_report_string =
            event.newData.stewards_report_string +
            this.stewardsLabel(event.newData["stewards_report_" + i]);
          stewards_report.push(event.newData["stewards_report_" + i]);
        } else if (event.data["stewards_report_" + i]) {
          event.newData.stewards_report_string =
            event.newData.stewards_report_string +
            this.stewardsLabel(event.data["stewards_report_" + i]);
          event.newData["stewards_report_" + i] =
            event.data["stewards_report_" + i];
          stewards_report.push(event.data["stewards_report_" + i]);
        }
      }
      if (
        event.newData.stewards_report_string.includes("Code not found") &&
        event.newValue
      ) {
        alert("Code not found, please correct");
        return "";
      }

      return await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_horse",
          stewards_report: stewards_report,
          meetingId: this.$props.meetingId,
          raceId: race["@_id"],
          horseId: event.newData["@_id"],
          user: this.current_user.username,
        },
      })
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Field updated.",
            life: 2000,
          });
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === event.newData["@_id"];
              })[0]
            )
          ] = event.newData;
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async submitResultsFormHandler(race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      if (!race.race_stage.toLowerCase().includes("results")) {
        alert("Only races at results stage can be finalised in this manner");
        return "";
      }
      let formUpdateData = {
        meetingId: this.meetingId,
        raceId: race["@_id"],
        results_approved: true,
        race_stage: "Results",
      };
      await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          ...formUpdateData,
          level: "processed_race",
          user: this.current_user.username,
        },
      })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Results approved.",
            life: 2000,
          });
          let targetResultsIndicator = document.getElementsByClassName(
            "j-results-" + race["@_id"]
          );
          console.log(targetResultsIndicator);
          for (var item of targetResultsIndicator) {
            item.classList.remove("pi-results-unchecked");
            item.classList.add("pi-results-checked");
          }
          // this.distributeResultRace(this.meetingId,race["@_number"])
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async distributeResultRace(meeting, raceNo) {
      const queryString = new URLSearchParams({
        id: this.meetingId,
        is_validated: false,
        race_no: raceNo,
      }).toString();

      return await API.put("MrCenApiGateway", `/distribute?${queryString}`)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `The results for race ${raceNo} at ${meeting} was successfully distributed.`,
            life: 5000,
          });
        })
        .catch((error) => {
          console.log(
            "An error occurred when trying to distribute this meeting: " +
              error.toString()
          );
          this.$toast.add({
            severity: "error",
            summary:
              "The meeting at " +
              meeting +
              " was not distributed because of an error.",
            life: 50000,
          });
        });
    },
    async onRowEditSaveResults(event, race) {
      if (this.$props.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      console.log(event);
      let body = event.newData;
      body.user = this.current_user.username;
      body.meetingId = this.$props.meetingId;
      body.raceId = race["@_id"];
      body.horseId = event.newData["@_id"];
      body.level = "processed_horse";
      body.positions = {
        "@_settling_down": event.newData.position_settling_down ?? "",
        "@_m1200": event.newData.position_m1200 ?? "",
        "@_m800": event.newData.position_m800 ?? "",
        "@_m400": event.newData.position_m400 ?? "",
        "@_finish": event.newData.finish_position ?? "",
      };
      body.finish_position = event.newData.finish_position ?? "";
      body.decimalprices = {
        "@_opening": event.newData.price_opening ?? "",
        "@_mid": event.newData.price_mid ?? "",
        "@_starting": event.newData.price_start ?? "",
      };
      body.prizemoney_race = event.newData.prizemoney_race;
      body.prizemoney_bonus = event.newData.prizemoney_bonus;
      if (!event.newData.prizemoney_race && race.prizes && race.prizes.prize) {
        for (var prize of race.prizes.prize) {
          if (prize["@_type"] == event.newData.finish_position) {
            body.prizemoney_race = prize["@_value"];
            event.newData.prizemoney_race = prize["@_value"];
          }
        }
      }
      body.margin = event.newData.margin;
      console.log(body);
      await API.put("MrCenApiGateway", `/processedmeetings`, { body: body })
        .then(() => {
          race.horses.horse[
            race.horses.horse.indexOf(
              race.horses.horse.filter((h) => {
                return h["@_id"] === event.newData["@_id"];
              })[0]
            )
          ] = event.newData;
          this.$toast.add({
            severity: "success",
            summary: "Row updated.",
            life: 2000,
          });
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });
    },
    async fetchMeetingDetails(uuid) {
      console.log("Now fetching meeting details");
      return await API.get("MrCenApiGateway", `/meeting?id=${uuid}`)
        .then(async (response) => {
          console.log("fetchMeetingDetails");
          console.log(response);
          this.races = response.data.processedMeetingData.meeting.races.race;
          await this.rebuildMeetingDetails(
            response.data.processedMeetingData.meeting
          );
          this.meetingDetails = response;
          return response;
        })
        .catch((error) => {
          this.errors.push(error);
          this.errored = true;
          this.alert = true;
        });
    },
    async rebuildMeetingDetails(meeting) {
      try {
        if (meeting.races !== undefined) {
          meeting.races.race.forEach((race) => {
            race.meeting_rail_position = meeting.rail_position;
            race.meeting_track = meeting.track["@_name"];
            if (!race.single_horse) {
              race.single_horse = false;
            }
            race.raceTotalPct = 0;
            if (race.sectional !== undefined) {
              race.sec_distance = race.sectional["@_distance"];
              race.sec_timing = race.sectional["@_time"];
              race.sec_indicator = race.sectional["@_location"];
            }
            if (race.restrictions !== undefined) {
              race.apprentice_claim =
                race.restrictions["@_jockey"] === "Apprentices Can Claim";
              race.jockey_rest = race.restrictions["@_jockey"];
              race.age_rest = race.restrictions["@_age"];
              race.sex_rest = race.restrictions["@_sex"];
            }
            if (race.classes !== undefined && race.classes["class"]) {
              race.class = race.classes["class"];
              race.second_class = "";
              race.third_class = "";
              if (race.classes.second_class) {
                race.second_class = race.classes.second_class;
                if (race.classes.third_class) {
                  race.third_class = race.classes.third_class;
                }
              }
            } else {
              race.classes = { class: "", class_id: 0 };
              race.class = "";
            }
            if (race.track !== undefined) {
              race.track_name = race.track["@_name"];
            }
            if (race.sec_indicator === "last") race.sec_indicator = "l";
            if (race.sec_indicator === "first") race.sec_indicator = "f";
            if (race.horses !== undefined) {
              for (var i = 0; i < race.horses.horse.length; i++) {
                if (race.horses.horse[i]["@_name"]) {
                  if (race.horses.horse[i].trainer !== undefined) {
                    race.horses.horse[i].trainerName =
                      race.horses.horse[i].trainer["@_name"] ?? "";
                    race.horses.horse[i].trainerId =
                      race.horses.horse[i].trainer["@_id"] ?? 0;
                  }
                  if (race.horses.horse[i].jockey != undefined) {
                    race.horses.horse[i].jockeyName =
                      race.horses.horse[i].jockey["@_name"] ?? "";
                    race.horses.horse[i].jockeyId =
                      race.horses.horse[i].jockey["@_id"] ?? 0;
                    race.horses.horse[i].jockeyWeight =
                      (race.horses.horse[i].jockey["@_apprentice_indicator"] ===
                      "Y"
                        ? "a"
                        : "") +
                      (race.horses.horse[i].jockey["@_allowance_weight"] ?? "");
                    race.horses.horse[i].jockeyApprentice =
                      race.horses.horse[i].jockey["@_apprentice_indicator"];
                    race.horses.horse[i].jockeyRidingWeight =
                      race.horses.horse[i].jockey["@_riding_weight"];
                  }
                  if (race.horses.horse[i].positions !== undefined) {
                    race.horses.horse[i].position_settling_down =
                      race.horses.horse[i].positions["@_settling_down"] ?? 0;
                    race.horses.horse[i].position_m400 =
                      race.horses.horse[i].positions["@_m400"] ?? 0;
                    race.horses.horse[i].position_m800 =
                      race.horses.horse[i].positions["@_m800"] ?? 0;
                    race.horses.horse[i].position_m1200 =
                      race.horses.horse[i].positions["@_m1200"] ?? 0;
                  }
                  if (race.horses.horse[i].decimalprices !== undefined) {
                    race.horses.horse[i].price_opening =
                      race.horses.horse[i].decimalprices["@_opening"];
                    race.horses.horse[i].price_mid =
                      race.horses.horse[i].decimalprices["@_mid"];
                    // race.horses.horse[i].price_mid_2 = race.horses.horse[i].decimalprices["@_mid_2"];
                    race.horses.horse[i].price_start =
                      race.horses.horse[i].decimalprices["@_starting"];
                  }
                  if (race.horses.horse[i].gear_changes !== undefined) {
                    var theGear = "";
                    if (
                      race.horses.horse[i].gear_changes.gear_change &&
                      race.horses.horse[i].gear_changes.gear_change[0]
                    ) {
                      var j = 0;
                      for (var gearItem of race.horses.horse[i].gear_changes
                        .gear_change) {
                        if (gearItem["@_name"] && gearItem["@_option"]) {
                          if (j > 0) {
                            theGear = theGear + ";";
                          }
                          theGear =
                            theGear +
                            gearItem["@_name"] +
                            ":" +
                            gearItem["@_option"];
                          j++;
                        } else if (gearItem["@_name"]) {
                          if (j > 0) {
                            theGear = theGear + ";";
                          }
                          theGear = theGear + gearItem["@_name"];
                          j++;
                        }
                      }
                      race.horses.horse[i].gear = theGear;
                    }
                  }
                  if (race.horses.horse[i].running_gear !== undefined) {
                    if (
                      race.horses.horse[i].running_gear.gear_item &&
                      race.horses.horse[i].running_gear.gear_item[0]
                    ) {
                      race.horses.horse[i].ran_gear =
                        race.horses.horse[i].running_gear.gear_item.join(";");
                    }
                  }
                  if (race.horses.horse[i].stewards_report) {
                    if (
                      Array.isArray(race.horses.horse[i].stewards_report) &&
                      race.horses.horse[i].stewards_report[0]
                    ) {
                      var stewards_reports =
                        race.horses.horse[i].stewards_report;
                      if (race.horses.horse[i].stewards_report[0].includes(" "))
                        stewards_reports =
                          race.horses.horse[i].stewards_report[0].split(" ");

                      race.horses.horse[i].stewards_report_1 =
                        stewards_reports[0];
                      race.horses.horse[i].stewards_report_string =
                        this.stewardsLabel(
                          race.horses.horse[i].stewards_report_1
                        );
                      if (stewards_reports[1]) {
                        race.horses.horse[i].stewards_report_2 =
                          stewards_reports[1];
                        race.horses.horse[i].stewards_report_string =
                          race.horses.horse[i].stewards_report_string +
                          this.stewardsLabel(
                            race.horses.horse[i].stewards_report_2
                          );
                      }
                      if (stewards_reports[2]) {
                        race.horses.horse[i].stewards_report_3 =
                          stewards_reports[2];
                        race.horses.horse[i].stewards_report_string =
                          race.horses.horse[i].stewards_report_string +
                          this.stewardsLabel(
                            race.horses.horse[i].stewards_report_3
                          );
                      }
                      if (stewards_reports[3]) {
                        race.horses.horse[i].stewards_report_4 =
                          stewards_reports[3];
                        race.horses.horse[i].stewards_report_string =
                          race.horses.horse[i].stewards_report_string +
                          this.stewardsLabel(
                            race.horses.horse[i].stewards_report_4
                          );
                      }
                      if (stewards_reports[4]) {
                        race.horses.horse[i].stewards_report_5 =
                          stewards_reports[4];
                        race.horses.horse[i].stewards_report_string =
                          race.horses.horse[i].stewards_report_string +
                          this.stewardsLabel(
                            race.horses.horse[i].stewards_report_5
                          );
                      }
                    } else {
                      race.horses.horse[i].stewards_report_string =
                        race.horses.horse[i].stewards_report;
                    }
                  }
                  if (race.horses.horse[i].betting) {
                    race.horses.horse[i].betting_pct =
                      Math.round(2 * (100 / race.horses.horse[i].betting)) / 2;
                    race.raceTotalPct =
                      race.raceTotalPct + race.horses.horse[i].betting_pct;
                    // console.log(horse.betting_pct,race.raceTotalPct)
                  }
                  if (race.horses.horse[i].weight !== undefined) {
                    race.horses.horse[i].totalWeight =
                      race.horses.horse[i].weight["@_total"];
                    race.horses.horse[i].allocatedWeight =
                      race.horses.horse[i].weight["@_allocated"];
                  }
                }
              }
            }
          });
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        // debugger;
      }
    },
    async enterMarket(race, confidence = 5) {
      console.log(race, confidence);
      let ratings = [];
      let tips = [];
      for (let the_horse of race.horses.horse) {
        if (the_horse.rating && !the_horse.scratched) {
          ratings.push({
            id: the_horse["@_id"],
            rating: the_horse.rating,
            rawPercent: 0,
            finalPercent: 0,
            price: 0,
          });
        }
        if (!the_horse.scratched && the_horse.tip)
          tips.push({ id: the_horse["@_id"], tip: the_horse.tip });
      }
      ratings.sort((a, b) => a.rating - b.rating);
      let rawPercent = 0;
      let curve = 1;
      let lastrating = 0;
      let lastratingAdj = 0;
      for (let horse of ratings) {
        if (horse.rating <= lastrating + 0.5) {
          lastratingAdj += 0.1;
        } else {
          lastratingAdj = 0;
        }
        lastrating = horse.rating;
        let ratingDiff = horse.rating - ratings[0].rating;
        let ratingadj = 1;
        let furtheradj = 1;
        if (
          ratingDiff >
          ratings[ratings.length - 1].rating - (ratings[0].rating + 2)
        )
          ratingadj = 3.5;
        else if (
          ratingDiff >
          ratings[ratings.length - 1].rating - (ratings[0].rating + 4)
        )
          ratingadj = 2.5;
        else if (
          ratingDiff >
          ratings[ratings.length - 1].rating - (ratings[0].rating + 7)
        )
          ratingadj = 1.5;
        else if (
          ratingDiff <
          ratings[ratings.length - 1].rating - (ratings[0].rating + 10)
        )
          furtheradj = 2;
        else if (
          ratingDiff <
          ratings[ratings.length - 1].rating - (ratings[0].rating + 12)
        )
          furtheradj = 2.5;
        else if (
          ratingDiff <
          ratings[ratings.length - 1].rating - (ratings[0].rating + 15)
        )
          furtheradj = 3;
        else if (
          ratingDiff <
          ratings[ratings.length - 1].rating - (ratings[0].rating + 20)
        )
          furtheradj = 5;
        curve =
          ratingDiff * (ratingadj + lastratingAdj) + confidence / furtheradj;
        horse.rawPercent = confidence * curve;
        rawPercent += horse.rawPercent;
      }
      const percentadj = (1.1 + ratings.length / 100) / rawPercent;
      let tipPct = 0;
      let tipHorses = [];
      let tipNum =
        ratings.length / 2 < 4
          ? 4
          : ratings.length / 2 > 8
          ? 8
          : Math.round(ratings.length / 2);
      FinalRating: for (let i = 0; i < ratings.length; i++) {
        let horse = ratings[i];
        horse.finalPercent = horse.rawPercent * percentadj * 100;
        horse.price = this.rationalizeMarket(
          Math.round((100 / horse.finalPercent) * 10) / 10
        );
        horse.finalPercent = Math.round((100 / horse.price) * 2) / 2;
        // console.log(horse);
        if (i + 1 > ratings.length - tipNum) {
          if (tips.length > 0)
            for (const tip of tips)
              if (tip.id === horse.id) continue FinalRating;
          horse.tipPercent = horse.finalPercent * horse.finalPercent;
          tipHorses.unshift(horse);
          tipPct += horse.tipPercent;
        }
      }
      if (tips.length < 4 && tips.length > 0) {
        // reorder tips to move everything up
        tips.sort((a, b) => parseInt(a.tip) - parseInt(b.tip));
        let i = 0;
        for (let tip of tips) {
          i++;
          if (parseInt(tip.tip) !== i) tip.tip = i;
        }
        let j = 0;
        while (i < 4) {
          i++;
          tips.push({ id: tipHorses[j].id, tip: i });
          j++;
        }
      } else if (tips.length === 0) {
        let i = 0;
        while (i < 4) {
          let totalTip = 0;
          let thisTip = Math.random() * tipPct;
          tipHorseLoop: for (const horse of tipHorses) {
            console.log(horse, tips);
            for (let tip of tips)
              if (tip.id === horse.id) continue tipHorseLoop;
            totalTip += horse.tipPercent;
            if (thisTip <= totalTip) {
              i++;
              tips.push({ id: horse.id, tip: i });
              tipPct = tipPct - horse.tipPercent;
              break;
            }
          }
        }
      }
      for (let the_horse of race.horses.horse) {
        for (const horse of ratings) {
          if (the_horse["@_id"] == horse.id) {
            the_horse.betting = horse.price;
            the_horse.betting_pct = horse.finalPercent;
            the_horse.tip = "";
          }
        }
        for (const horse of tips) {
          if (the_horse["@_id"] == horse.id) {
            the_horse.tip = horse.tip;
          }
        }
        if (the_horse.scratched) {
          the_horse.betting = "";
          the_horse.betting_pct = 0;
          the_horse.tip = "";
        }

        race.raceTotalPct += the_horse.betting_pct;
      }
      race.confidence = confidence;
      this.selectedRace = race;
      this.selectedRace.updateMarket = true;
      await this.updateRace();
      this.selectedRace = null;
    },
    rationalizeMarket(num) {
      if (num < 1) num = 501;
      else if (num < 1.1) num = Math.round(num * 100) / 100;
      else if (num < 1.3) num = Math.round(num * 50) / 50;
      else if (num < 2.5) num = Math.round(num * 20) / 20;
      else if (num < 4) num = Math.round(num * 10) / 10;
      else if (num < 5) num = Math.round(num * 5) / 5;
      else if (num < 10) num = Math.round(num * 2) / 2;
      else if (num < 21) num = Math.round(num);
      else if (num < 31) num = Math.round(num / 5) * 5 + 1;
      else if (num < 101) num = Math.round(num / 10) * 10 + 1;
      else if (num < 201) num = Math.round(num / 50) * 50 + 1;
      else if (num < 501) num = Math.round(num / 100) * 100 + 1;
      else if (num > 501) num = 501;
      return num;
    },
    formatCurrency(value) {
      if (value !== undefined && value !== null)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        });
      else return value;
    },
    selectThis(event) {
      console.log(event);
      event.target.select();
    },
    stewardsLabel(report) {
      if (this.stewards_options_3[report] != null)
        return this.stewards_options_3[report] + ". ";
      var reportSplit = report.split("");
      if (
        this.stewards_options_1[reportSplit[0]] &&
        this.stewards_options_2[reportSplit[1]]
      )
        return (
          this.stewards_options_1[reportSplit[0]].trim() +
          " " +
          this.stewards_options_2[reportSplit[1]].trim() +
          ". "
        );
      return "Code not found: " + report;
    },
    formatTrackCond(cond) {
      var map = new Map([
        ["Y0", "Synthetic"],
        ["F1", "Fast(1)"],
        ["F2", "Fast(2)"],
        ["M1", "Firm(1)"],
        ["M2", "Firm(2)"],
        ["G3", "Good(3)"],
        ["G4", "Good(4)"],
        ["O5", "Soft(5)"],
        ["O6", "Soft(6)"],
        ["O7", "Soft(7)"],
        ["H8", "Heavy(8)"],
        ["H9", "Heavy(9)"],
        ["H10", "Heavy(10)"],
        ["F0", "Firm"],
        ["G0", "Good"],
        ["S0", "Soft"],
        ["H0", "Heavy"],
        ["Fast", "Fast"],
        ["Dead", "Dead"],
        ["Slow", "Slow"],
      ]);
      let result = map.get(cond);
      if (result == undefined) {
        result = cond;
      }
      return result;
    },
    offMarginIsBad(margin) {
      let marginBad = false;
      if (!margin) return false;
      if (margin.value) margin = margin.value;
      margin = margin.toString();
      if (
        this.isNumeric(margin) &&
        margin.split(".")[1] &&
        (margin.split(".")[1].length > 1 ||
          ["1", "2", "4", "6", "7", "9"].includes(margin.split(".")[1]))
      )
        marginBad = true;
      if (
        !this.isNumeric(margin) &&
        ![
          "DH",
          "NS",
          "SHH",
          "HH",
          "SH",
          "HD",
          "LH",
          "HN",
          "SN",
          "NK",
          "LN",
        ].includes(margin)
      )
        marginBad = true;
      console.log("bad margin!", margin);

      return marginBad;
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
  },
  computed: {
    getMeetingErrors() {
      this.races.forEach((race) => {
        if (
          race.records.track_record &&
          race.records.track_record.errors &&
          race.records.track_record.errors.length
        ) {
          this.meeting_errors.push(race.records.track_record.errors);
        }
        //console.log(race.horses.horse);
        race.horses.horse.forEach((horse) => {
          if (horse.errors && horse.errors.length) {
            this.meeting_errors.push(horse.errors);
          }
        });
      });
      //console.log(this.meeting_errors.join('\r\n'));
      return this.meeting_errors.toString();
    },
    currentMeetingStage() {
      return this.meetingDetails?.data?.meetingStage || null;
    },
  },
};
</script>

<style scoped>
.double {
  display: flex;
  justify-content: space-between;
}

.formkit-form {
  padding: 10px;
}
.tinyInput,
.p-inputtext * {
  width: 40px;
}

.smallInput,
.p-inputtext * {
  width: 80px;
}
.stewInput {
  border: 1px solid #999;
}

.wideInput,
.p-inputtext * {
  width: 600px;
}
.fullInput,
.p-inputtext * {
  width: 100%;
}
.smallInputCurrency,
.p-inputnumber-input * {
  width: 100px;
}

.smallInputCurrency,
.p-input-text * {
  width: 100px;
}
.smallInputCurrency > input {
  max-width: 100%;
}

.mr-3 {
  color: #689f38;
  background: transparent;
  border: 1px solid #689f38;
}
.mr-3:enabled:hover {
  color: #fff;
  background: #689f38;
  border: 1px solid #689f38;
}

.p-scratched-horse {
  background: rgba(255, 255, 255, 0.9);
  width: calc(85% - 40px);
  position: absolute;
  height: 48px;
  right: 40px;
  margin-top: -16px;
  color: red;
  font-size: 20px;
  line-height: 48px;
  padding-left: 20%;
}
.p-scratched-horse-sml {
  height: 36px;
  right: 40px;
  margin-top: -18px;
  color: red;
  font-size: 20px;
  line-height: 36px;
  padding-left: 20%;
}
.p-datatable-sm .p-sortable-column-icon .pi-sort-alt::before {
  content: "";
}
.p-datatable-sm .p-sortable-column-icon .pi-sort-amount-up-alt::before {
  content: "";
}
.p-datatable-sm .p-sortable-column-icon .pi-sort-amount-down::before {
  content: "";
}
.p-datatable-sm .p-sortable-column-badge {
  display: none;
}

.pi-results-unchecked {
  color: red;
  background: none;
  border: none;
}
.pi-results-checked {
  color: #689f38;
  background: none;
  border: none;
}
.field {
  margin-bottom: 0;
  padding: 8px 12px;
}
.p-tip-button {
  width: 100px;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 0 25%;
  background: #ddd;
}
.p-tip-button:hover {
  background: #fff;
}
</style>
